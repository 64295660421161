import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Image from "../components/image"
import Layout from "../components/layout"

import SEO from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

import { faClock } from "@fortawesome/free-regular-svg-icons"

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Container,Grid} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  phrase: {
    color: "gray",
    textAlign: 'center',
    paddingTop: 150,
    marginLeft: 30,
    marginBottom: 50,
    marginRight: 30,
  },
  image_tilte: {
    position: "absolute",
    top: "40%", 
    left: "10%",
    right: "10%",
    color: "white"
  },
  posts: {
    paddingTop: "5vh",
    paddingBottom: "15vh",
  }
}))

const BlogTemp = ({ data, location, pageContext }) => {

  const breakpoints = useBreakpoint();
  const classes = useStyles();
  return(
  <Layout location={location} breakpoints = {breakpoints}>
    <SEO
      title="記事一覧"
      description="の記事一覧です"
      pagepath={location.pathname}
    />
    <div  style={{position: "fixed", top: 0, minWidth: "100%", height: "40vh", zIndex: -1,backgroundColor: "gainsboro"}}><Image filename="overview_background.png" style={{height: "inherit"}}/></div>
    <div className={classes.phrase} style = {{color: "white",fontSize: breakpoints?.sm ? 28 : breakpoints.md ? 36 : 42}}>
      記事一覧
    </div>
    <div style={{backgroundColor: "white", paddingTop: 150}}>

      <Container fix>
        <div className={classes.posts}>
          <Grid container spacing={3}>
          {data.allContentfulBlogPost.edges.map(({ node }) => (
            
            <Grid item xs={12} sm={6}>
            
              <article className="post" key={node.id} style={{position: "relative"}}>
                <Link to={`/blog/post/${node.slug}/`}>
                    <Img
                      fluid={node.eyecatch.fluid}
                      alt={node.eyecatch.description}
                      style={{ height: "100%" }}
                      loading="eager"
                      durationFadeIn={100}
                    />
                  <div className={classes.image_tilte} style={{fontSize: breakpoints?.sm ? 14 : breakpoints.md ? 18 : 22}}>{node.title}</div>
                </Link>
                <time dateTime={node.publishDate}>
                  <FontAwesomeIcon icon={faClock} />
                  {node.publishDateJP}
                </time>
              </article>
            </Grid>
          ))}
          </Grid>
        </div>
      </Container>
    </div>

    <ul className="pagenation">
      {!pageContext.isFirst && (
        <li className="prev">
          <Link
            to={
              pageContext.currentPage === 2
                ? `/blog/`
                : `/blog/${pageContext.currentPage - 1}/`
            }
            rel="prev"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>前のページ</span>
          </Link>
        </li>
      )}
      {!pageContext.isLast && (
        <li className="next">
          <Link to={`/blog/${pageContext.currentPage + 1}/`} rel="next">
            <span>次のページ</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </li>
      )}
    </ul>
  </Layout>
)}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { order: DESC, fields: publishDate }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          id
          slug
          publishDateJP: publishDate(formatString: "YYYY年MM月DD日")
          publishDate
          description
          eyecatch {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
            description
          }
        }
      }
    }
  }
`

export default BlogTemp
